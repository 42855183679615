export const places = [
  'Barcelone',
  'Civitavecchia',
  'Genova',
  'La goulette',
  'Rades',
  'Livorno',
  'Marseille',
  'Napoli',
  'Salerno',
  'Autre'
];
